type VarKeys<T extends Record<string, string>> = {
  [Key in keyof T as `$${string & Key}`]: T[Key];
};
export type ThemeColor = keyof VarKeys<typeof colors>;
type OnlyString = string & Record<never, never>;
export type ThemeColorOrAnyColor = ThemeColor | OnlyString;
export type ThemeDarkLightColor<T = ThemeColorOrAnyColor> = {
  dark: T;
  light: T;
};

const colors = {
  transparent: 'transparent',
  brandBlack: '#000000',
  brandGray: '#939697',
  brandRed: '#C6002B',
  brandWhite: '#FFFFFF',
  blue100: '#9ADAFF',
  blue150: '#84D0FC',
  blue200: '#75C8F7',
  blue250: '#5FBBF0',
  blue300: '#51B2EA',
  blue350: '#42AAE6',
  blue400: '#3CA2DD',
  blue450: '#2A97D6',
  blue500: '#288BD3',
  blue550: '#2880D3',
  blue600: '#0055B7',
  gray100: '#F6F8F9',
  gray150: '#EDF1F3',
  gray200: '#E6EAED',
  gray250: '#DADFE3',
  gray300: '#A9AFB2',
  gray350: '#96979F',
  gray400: '#717277',
  gray450: '#55565B',
  gray500: '#3F4447',
  gray550: '#2D3134',
  gray600: '#27292D',
  green100: '#ACE7C0',
  green150: '#A6E4BB',
  green200: '#9CE2B4',
  green250: '#90E1AB',
  green300: '#8BDEA7',
  green350: '#80DB9F',
  green400: '#78D297',
  green450: '#6CC58B',
  green500: '#62AA7B',
  green550: '#519769',
  green600: '#46815A',
  magenta100: '#FCEBF5',
  magenta150: '#FBD7ED',
  magenta200: '#F9C4E4',
  magenta250: '#F7B0DC',
  magenta300: '#F59CD4',
  magenta350: '#F389CB',
  magenta400: '#F175C3',
  magenta450: '#F061BB',
  magenta500: '#EE4EB3',
  magenta550: '#ED39AB',
  magenta600: '#EC26A3',
  magenta650: '#C80E83',
  orange100: '#FBC8B3',
  orange150: '#F9B699',
  orange200: '#F8A480',
  orange250: '#F69166',
  orange300: '#F57F4D',
  orange350: '#F36D33',
  orange400: '#F65D1C',
  orange450: '#F84E1B',
  orange500: '#F14510',
  orange550: '#E44311',
  orange600: '#D03E11',
  orange650: '#A82700',
  purple100: '#C7A3F5',
  purple150: '#C19BF2',
  purple200: '#B895F2',
  purple250: '#AC8EEC',
  purple300: '#A586E5',
  purple350: '#9880DF',
  purple400: '#A071DC',
  purple450: '#9664D5',
  purple500: '#8D5BCD',
  purple550: '#8043BD',
  purple600: '#793CB7',
  purple650: '#6117AB',
  red100: '#F4ADB1',
  red150: '#F4A0A0',
  red200: '#F79096',
  red250: '#F9838A',
  red300: '#F4747C',
  red350: '#F94E5C',
  red400: '#E22C3A',
  red450: '#D62836',
  red500: '#D2252A',
  red550: '#CC032E',
  red600: '#C6002B',
  red650: '#B30027',
  teal100: '#A3E2EB',
  teal150: '#8EDFEA',
  teal200: '#84DAE5',
  teal250: '#77D4E0',
  teal300: '#68C8D5',
  teal350: '#5FC0CD',
  teal400: '#60BAC6',
  teal450: '#57B1BC',
  teal500: '#509DA7',
  teal550: '#3E919C',
  teal600: '#267883',
  teal650: '#186B76',
  yellow100: '#FFF8E9',
  yellow150: '#FEF2D4',
  yellow200: '#FEECBF',
  yellow250: '#FEE5AA',
  yellow300: '#FDDF96',
  yellow350: '#FDD982',
  yellow400: '#FDD36F',
  yellow450: '#FDCC5D',
  yellow500: '#FDC64C',
  yellow550: '#FDC040',
  yellow600: '#FCBA38',
  yellow650: '#CC8838',
  gradientBrightBlue: 'linear-gradient(147.65deg, #0055B7 0%, #2880D3 100%)',
  gradientEveningSky:
    'linear-gradient(147.65deg, #2A97D6 9.1%, #793CB7 99.36%)',
  gradientFog: 'linear-gradient(153.68deg, #F6F8F9 0%, #E6EAED 100%)',
  gradientKeyLime: 'linear-gradient(153.68deg, #8BDEA7 0%, #60BAC6 100%)',
  gradientPurple: 'linear-gradient(119.4deg, #793C8A 0%, #8071AF 91.41%)',
  gradientSteel: 'linear-gradient(153.68deg, #C5CDD2 0%, #717277 100%)',
  gradientSunrise: 'linear-gradient(153.87deg, #F9B699 0%, #E22C3A 100%)',
  gradientSunset: 'linear-gradient(153.87deg, #8B6AE9 0%, #F4747C 100%)',
  gradientSunsetReversed:
    'linear-gradient(154.6deg, #F94E5C -27.55%, #793CB7 125.12%)',
  gradientPlusHorizontal:
    'linear-gradient(134.77deg, #6117AB 21.2%, #0055B7 99.45%);',
  gradientAllAccessHorizontal:
    'linear-gradient(153.87deg, #C80E83 0.84%, #6117AB 100%);',
  gradientPlusDarkVertical:
    'linear-gradient(180deg, #AC8EEC 0%, rgba(246, 248, 249, 0.00) 97.52%)',
  gradientAllAccessDarkVertical:
    'linear-gradient(180deg, #F59CD4 0%, rgba(246, 248, 249, 0.00) 97.52%)',
  gradientPlusLightVertical:
    'linear-gradient(180deg, #6117AB 0%, rgba(246, 248, 249, 0.00) 97.52%)',
  gradientAllAccessLightVertical:
    'linear-gradient(180deg, rgba(200, 14, 131, 0.50) 0%, rgba(246, 248, 249, 0.00) 97.52%)',
} as const;

const shadows = {
  elevation1: '0 1px 3px rgba(0, 0, 0, 0.15)',
  elevation4: '0 3px 5px rgba(0, 0, 0, 0.15)',
  elevation6: '0 5px 8px rgba(0, 0, 0, 0.15)',
  elevation8: '0 8px 24px rgba(0, 0, 0, 0.15)',
  elevationUp1: '0 -1px 3px rgba(0, 0, 0, 0.15)',
  elevationUp4: '0 -3px 6px rgba(0, 0, 0, 0.15)',
  focusOutlineDark: `0 0 0 0.2rem ${colors.brandWhite}`,
  focusOutlineLight: `0 0 0 0.2rem ${colors.brandBlack}`,
} as const;

export const themeTokens = {
  space: {
    0: '0rem',
    1: '0.1rem',
    2: '0.2rem',
    4: '0.4rem',
    6: '0.6rem',
    8: '0.8rem',
    10: '1.0rem',
    12: '1.2rem',
    14: '1.4rem',
    16: '1.6rem',
    18: '1.8rem',
    24: '2.4rem',
    28: '2.8rem',
    32: '3.2rem',
    36: '3.6rem',
    40: '4.0rem',
    48: '4.8rem',
    56: '5.6rem',
    64: '6.4rem',
    91: '9.1rem',
    128: '12.8rem',
  },
  borderWidth: {
    0: '0rem',
    1: '0.1rem',
    2: '0.2rem',
    3: '0.3rem',
    4: '0.4rem',
  },
  colors,
  filters: {
    dropShadow1: `drop-shadow(${shadows.elevation1})`,
    dropShadow4: `drop-shadow(${shadows.elevation4})`,
    dropShadow6: `drop-shadow(${shadows.elevation6})`,
    dropShadow8: `drop-shadow(${shadows.elevation8})`,
    dropShadowUp1: `drop-shadow(${shadows.elevationUp1})`,
    dropShadowUp4: `drop-shadow(${shadows.elevationUp4})`,
  },
  fontSize: {
    6: '0.6rem',
    10: '1.0rem',
    11: '1.1rem',
    12: '1.2rem',
    13: '1.3rem',
    14: '1.4rem',
    15: '1.5rem',
    16: '1.6rem',
    17: '1.7rem',
    18: '1.8rem',
    20: '2.0rem',
    21: '2.1rem',
    24: '2.4rem',
    32: '3.2rem',
    40: '4.0rem',
  },
  letterSpacing: {
    0: '-0.02rem',
    1: '-0.05rem',
    2: '-0.06rem',
    3: '-0.2rem',
    4: '0rem',
    5: '0.1rem',
    6: '-0.1rem',
  },
  lineHeight: {
    6: '0.6rem',
    8: '0.8rem',
    10: '1rem',
    14: '1.4rem',
    15: '1.5rem',
    16: '1.6rem',
    18: '1.8rem',
    19: '1.9rem',
    20: '2.0rem',
    21: '2.1rem',
    22: '2.2rem',
    24: '2.4rem',
    26: '2.6rem',
    28: '2.8rem',
    30: '3.0rem',
    38: '3.8rem',
    40: '4.0rem',
    45: '4.5rem',
    48: '4.8rem',
  },
  radius: {
    1: '0.125rem',
    2: '0.25rem',
    3: '0.3rem',
    4: '0.4rem',
    5: '0.5rem',
    6: '0.6rem',
    16: '1.6rem',
    18: '1.8rem',
    999: '99rem',
  },
  stroke: {
    1: '0.13rem',
  },
  size: {
    0: `${(0 / 12) * 100}%`,
    1: `${(1 / 12) * 100}%`,
    2: `${(2 / 12) * 100}%`,
    3: `${(3 / 12) * 100}%`,
    4: `${(4 / 12) * 100}%`,
    5: `${(5 / 12) * 100}%`,
    6: `${(6 / 12) * 100}%`,
    7: `${(7 / 12) * 100}%`,
    8: `${(8 / 12) * 100}%`,
    9: `${(9 / 12) * 100}%`,
    10: `${(10 / 12) * 100}%`,
    11: `${(11 / 12) * 100}%`,
    12: `${(12 / 12) * 100}%`,
  },
  shadows,
  zIndices: {
    0: '0',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
  },
} as const;
